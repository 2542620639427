import React from 'react'
import { DefaultLayout } from '../components/Layout'
import { Ellipsis } from '../components/Typography'

const CareersPage = () => (
  <DefaultLayout>
    <h1>
      Careers
      <Ellipsis />
    </h1>
  </DefaultLayout>
)

export default CareersPage
